import React, { useState, useEffect } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";

const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <button
        className="fixed h-10 w-10 text-2xl rounded-full text-gray-light flex-c
         bg-primary hover:bg-secondary bottom-4 right-4 z-50"
        onClick={scrollToTop}>
        <AiOutlineArrowUp />
      </button>
    )
  );
};

export default ScrollTop;
